@import "breakpoints";

:root {
  --primary: #0070BA;
  --primary-hover: #0070BB;
  --primary-dark: #0062A3;
  --secondary: #49C5B1;
  --secondary-dark: #3AB6A2;
  --accent: #0070BA;
  --white: #FFFFFF;
  --orange: #FBA03D;
  --Bleu-clair-hellio: #ECFFFC;
  --Gray-Background-800: #F4F4F4;
  --GrisIndustriels-8: #AEAAA7;
  --sucess: #20A76A;
  --active: #DBF1FF;
  --primary-text: #3A3A3A;
  --secondary-text: #3A3A3AE0;
  --grey-text: #C6C2BE;
}

/*Button overrides*/

.button-override {
  border-radius: 100px;
  padding-left: 24px;
  padding-right: 24px;
  font-weight: 600;
  font-size: 16px;
  box-shadow: none;
  @media screen and (max-width: $sm) {
    padding-left: 16px;
    padding-right: 16px;
    font-size: 14px;
    font-weight: 400;
  }
}

.ant-btn-variant-outlined {
  &:hover {
    background-color: var(--primary-hover) !important;
    border-color: var(--primary-hover) !important;
    color: var(--white) !important;
  }
}
.ant-btn-variant-outlined.hover-white {
  &:hover {
    background-color: var(--white) !important;
    border-color: var(--primary-hover) !important;
    color: var(--primary-hover) !important;
  }
}

/* Input overrides */
.input-override {
  line-height: 24px;
  padding: 7px 12px;
}

.textarea-override textarea {
  padding: 7px 12px;
}

/* Input label overrides */
label.ant-form-item-required::before {
  display: none!important;
}
label.ant-form-item-required::after {
  content: " *" !important;
  visibility: visible !important;
  color: #F13538;
}


.ant-form-item-control .ant-form-item-explain {
  margin: 12px 0 24px 0;
}

.label-extend {
  label {
    width: 100%;
  }

  label:after {
    display: none;
  }
}

.disable-success-feedback .ant-form-item-feedback-icon-success {
  display: none;
}

.ant-form-item-explain-error {
  white-space: pre;
}

/*Left menu*/

aside.slider-override, .slider-override ul {
  background-color: var(--primary) !important;
}

.slider-override .ant-menu-item {
  margin: 16px;
  width: calc(100% - 32px);
  box-sizing: border-box;
  color: var(--white) !important;
}

@media screen and (max-width: $sm) {
  .slider-override .ant-menu-item {
    margin: 8px 16px;
  }
}

.slider-override .ant-menu-item-selected,
.slider-override .ant-menu-item:hover {
  background-color: var(--primary-dark) !important;
}

.slider-override .ant-menu-title-content {
  font-weight: 400 !important;
}

.slider-override .ant-menu-item {
  padding: 8px !important;
}

.slider-override .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}

.slider-override .logout {
  margin: 16px;
  background: rgba(255, 255, 255, 0.10);
}


/*modal*/
.ant-modal-title {
  font-size: 16px !important;
}

.ant-modal-content {
  padding: 16px!important;
}

.modal-vertical .ant-modal-confirm-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  text-align: center;
}

.modal-vertical .ant-modal-confirm-btns {
  text-align: center;
}

.ant-modal-confirm-success .anticon {
  color: var(--sucess) !important;
}

.ant-action-center .ant-modal-footer {
  text-align: center;
}

/*select*/
.ant-select-selector {
  padding: 5px 12px !important;
}

.ant-select-outlined {
  .ant-select-selection-placeholder {
    color: var(--secondary-text) ;
  }
}

.ant-select-item {
  padding: 8px 10px !important;
}

.ant-select-item-option-selected, .ant-select-item-option-active {
  background: transparent !important;
}

.ant-select-selection-search {
  margin-left: 0 !important;
}

.custom-select-item {
  padding: 8px;
  transition-duration: 250ms;
  border-radius: 2px;
  gap: 8px;
  display: flex;
  align-items: center;
  background-color: var(--white);
  border: none;
  width: 100%;
}

.ant-select-item-option-active .custom-select-item {
  background: var(--active) !important;
}

.ant-select-item-option-state {
  display: none !important;
}


/*Select Chip*/
.ant-select-selection-item-content {
  font-weight: 400 !important;
  font-size: 12px;
}

/* Checkbox */
.ant-checkbox {
  color: var(--secondary-text);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--accent);
  border-color: var(--accent);
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: var(--accent);
}

/*Popover*/
.ant-popover-inner {
  border-radius: 6px !important;
}

div.ant-typography, p.ant-typography {
  margin-bottom: inherit;
}

.no-text-margin .ant-typography {
  margin-bottom: 0;
}

.m-0 {
  margin: 0 !important;
}

.page-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*TAG*/
.ant-tag svg {
  margin-right: 4px;
}


.tabs-without-divider {
  .ant-tabs-nav {
    margin-bottom: 0;

    &:before {
      border-bottom: none;
    }
  }
}


.custom-inline-steeps {
  .ant-steps-item-tail {
    top: calc(13px / 2 + calc(var(--ant-padding-xs) + var(--ant-line-width))) !important;
  }

  .ant-steps-item-container {
    &:hover {
      cursor: default !important;
      background-color: transparent !important;
    }
  }

  .ant-steps-item-icon {
    width: 13px !important;
    height: 13px !important;
  }

  .ant-steps-item-wait {
    .ant-steps-icon-dot {
      border-radius: 20px !important;
      background-color: var(--grey-text) !important;
      position: relative;
    }
  }

  .ant-steps-item-finish, .ant-steps-item-process {
    .ant-steps-icon-dot {
      border-radius: 20px !important;
      background-color: var(--primary) !important;
      position: relative;
    }

    .ant-steps-item-title {
      color: var(--primary) !important;
    }

    .ant-steps-item-tail {
      //position: relative;
      &:after {
        background-color: var(--primary) !important;
      }
    }
  }

  .ant-steps-item-process {
    .ant-steps-item-tail {
      &:before {
        z-index: 10;
        content: "";
        left: 50%;
        right: 0;
        height: var(--ant-line-width);
        top: calc(50% - var(--ant-line-width) / 2);
        position: absolute;
        background-color: var(--ant-color-border-secondary) !important;
      }
    }

    .ant-steps-item-icon {
      &:after {
        width: 8px;
        height: 8px;
        content: url("../assets/icons/CheckWhite.svg");
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, calc(-50% - 3px));
        color: var(--white);
      }
    }
  }
}

.ant-table-no-header-bg {
  .ant-table-thead .ant-table-cell {
    background-color: transparent !important;

    &:before {
      display: none;
    }
  }
}

.ant-table-sorter-start {
  .ant-table-column-sorters {
    .ant-table-column-title {
      flex: none;
      margin-right: 8px;
    }

    justify-content: flex-start;
  }
}

.ant-table-header-gray {
  .ant-table-thead {
    .ant-table-cell {
      font-weight: 400;
      color: var(--GrisIndustriels-8);
    }
  }
}


// Upload
.ant-upload-list {
  margin: 0;
}

.all-child-leading-none {
  .ant-typography {
    line-height: 1!important;
  }
}

.ant-btn-variant-text:hover {
  background: none!important;
  text-decoration: underline;
}